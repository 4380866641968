var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Novo Tipo de Intervenção"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Código","label-for":"h-codigo"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-codigo","placeholder":"Código","maxlength":"15"},model:{value:(_vm.tipoIntervencao.codigo),callback:function ($$v) {_vm.$set(_vm.tipoIntervencao, "codigo", $$v)},expression:"tipoIntervencao.codigo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Descrição","label-for":"h-descricao"}},[_c('validation-provider',{attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-descricao","placeholder":"Descrição","maxlength":"50"},model:{value:(_vm.tipoIntervencao.descricao),callback:function ($$v) {_vm.$set(_vm.tipoIntervencao, "descricao", $$v)},expression:"tipoIntervencao.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tempo Fixo","label-for":"h-tempoFixo"}},[_c('validation-provider',{attrs:{"name":"tempoFixo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"tempoFixo","raw":false,"options":_vm.options.number,"placeholder":"0"},model:{value:(_vm.tipoIntervencao.tempoFixo),callback:function ($$v) {_vm.$set(_vm.tipoIntervencao, "tempoFixo", $$v)},expression:"tipoIntervencao.tempoFixo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tempo Mínimo","label-for":"h-tempoDebMin"}},[_c('validation-provider',{attrs:{"name":"tempoDebMin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"tempoDebMin","raw":false,"options":_vm.options.number,"placeholder":"0"},model:{value:(_vm.tipoIntervencao.tempoDebMin),callback:function ($$v) {_vm.$set(_vm.tipoIntervencao, "tempoDebMin", $$v)},expression:"tipoIntervencao.tempoDebMin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Implica Deslocações","label-for":"ImplicaDeslocacoes","label-class":"mb-1"}},[_c('b-form-checkbox',{attrs:{"id":"ImplicaDeslocacoes","value":"1"},model:{value:(_vm.tipoIntervencao.ImplicaDeslocacoes),callback:function ($$v) {_vm.$set(_vm.tipoIntervencao, "ImplicaDeslocacoes", $$v)},expression:"tipoIntervencao.ImplicaDeslocacoes"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.switchLoc()}}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }